import { Vector2 } from '../utils'

export const MAX_RECURSION_DEPTH = 13
export const PARENT_PADDING_MATRIX = initMatrix(3, 3)
export const MAX_NODES = 200
PARENT_PADDING_MATRIX[0][0] = [0.9, 0.9]
PARENT_PADDING_MATRIX[0][1] = [0.84, 0.84]
PARENT_PADDING_MATRIX[0][2] = [0.82, 0.82]

PARENT_PADDING_MATRIX[1][0] = [0.84, 0.84]
PARENT_PADDING_MATRIX[1][1] = [0.82, 0.82]
PARENT_PADDING_MATRIX[1][2] = [0.76, 0.76]

PARENT_PADDING_MATRIX[2][0] = [0.82, 0.82]
PARENT_PADDING_MATRIX[2][1] = [0.76, 0.76]
PARENT_PADDING_MATRIX[2][2] = [0.75, 0.75]

export const CHILD_PADDING_MATRIX = initMatrix(3, 3)
CHILD_PADDING_MATRIX[0][0] = [1, 1]
CHILD_PADDING_MATRIX[0][1] = [0.91, 0.91]
CHILD_PADDING_MATRIX[0][2] = [0.91, 0.91]
CHILD_PADDING_MATRIX[1][0] = [0.91, 0.91]
CHILD_PADDING_MATRIX[1][1] = [0.91, 0.91]
CHILD_PADDING_MATRIX[1][2] = [0.91, 0.91]
CHILD_PADDING_MATRIX[2][0] = [0.91, 0.91]
CHILD_PADDING_MATRIX[2][1] = [0.91, 0.91]
CHILD_PADDING_MATRIX[2][2] = [0.91, 0.91]

function initMatrix(cols: number, rows: number): Vector2[][] {
  const result: Vector2[][] = []

  for (let x = 0; x < cols; x++) {
    result[x] = []
    for (let y = 0; y < rows; y++) {
      result[x][y] = [1, 1]
    }
  }
  return result
}
