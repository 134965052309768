import React, { useEffect, useState } from 'react'
import './App.css'
import { Svg } from '@svgdotjs/svg.js'
import draw from './artwork/drawer'
import { sleep } from './utils'

function App() {
  const [svg, setSvg] = useState<undefined | Svg>(undefined)
  useEffect(() => {
    const svgInstance = draw()
    setSvg(svgInstance)
    return () => {
      document.body.removeChild(svgInstance.node)
      console.log('Garbage collecting...')
    }
  }, [])

  const [downloadUrl, setDownloadUrl] = useState('')

  async function save(): Promise<void> {
    if (!svg) return

    const svgData: any = svg.node
    const serializer = new XMLSerializer()
    let source = serializer.serializeToString(svgData)

    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      )
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      )
    }

    source = '<?xml version="1.0" standalone="no"?>\r\n' + source

    const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)

    setDownloadUrl(url)
    await sleep(250)
    document.getElementById('download-button')?.click()
  }

  return (
    <div className='App'>
      <div className='controls' style={{ display: 'none' }}>
        <button type='button' onClick={save}>
          Download SVG
        </button>
        <a
          id='download-button'
          style={{ display: 'none' }}
          rel='noreferrer'
          href={downloadUrl}
          target='_blank'
          download={`markovs-dream-print-${+new Date()}.svg`}
        >
          download svg
        </a>
      </div>
    </div>
  )
}

export default App
