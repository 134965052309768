import { Svg, SVG, Path, Container, Rect, Gradient } from '@svgdotjs/svg.js'
import { createElement } from 'react'
import { add, between, div, div2, mul, random, round, sub, sub2, Vector2 } from '../utils'
import { getRandomGradientFill } from './colour'
import {
  CHILD_PADDING_MATRIX,
  MAX_RECURSION_DEPTH,
  PARENT_PADDING_MATRIX,
  MAX_NODES
} from './const'

export default function draw(): Svg {
  const dimensions: Vector2 = [500, 500]
  const svg = SVG()
    .addTo('body')
    .size(...dimensions)
  const position: Vector2 = [1, 1]
  drawGroup(svg, position, dimensions, 0)
  return svg
}

function roundedRect(
  parent: Container,
  position: Vector2,
  dimensions: Vector2,
  fill: Gradient
): Rect {
  const min = Math.min(...dimensions)
  const rect = parent
    .rect(...dimensions.map(round))
    .attr({ fill, x: round(position[0], 1), y: round(position[1], 1) })
    .radius(round(min * between(0.43, 0.49)))
  return rect
}

function squircle(
  parent: Container,
  position: Vector2,
  dimensions: Vector2,
  fill: Gradient
): Path {
  const a = [0, round(dimensions[1] / 2, 2)]
  const b = [round(dimensions[0] / 2, 2), 0]
  const c = [dimensions[0], dimensions[1] / 2]
  const d = [round(dimensions[0] / 2, 2), dimensions[1]]
  const pull = dimensions.map(n => n * 0.15) as Vector2
  const [x, y] = position
  const path = parent.path(`

    M
      ${a[0] + x},${a[1] + y}

    C
      ${a[0] + x},${pull[1] + y}
      ${pull[0] + x},${b[1] + y}
      ${b[0] + x},${b[1] + y}

    S
      ${c[0] + x},${pull[1] + y}
      ${c[0] + x},${c[1] + y}
      ${c[0] - pull[0] + x},${d[1] + y}
      ${d[0] + x},${d[1] + y}
      ${a[0] + x},${d[1] - pull[1] + y}
      ${a[0] + x},${a[1] + y}

  `).attr({ fill })

  return path
}

const xmlns = "http://www.w3.org/2000/svg"
let totalNodes = MAX_NODES

function drawGroup(
  parent: Container,
  position: Vector2,
  dimensions: Vector2,
  depth: number
): void {
  totalNodes -= 1
  if (depth > MAX_RECURSION_DEPTH) {
    console.log('max. depth reached')
    return
  }

  if (totalNodes <= 0) {
    console.log('max. nodes reached')
    return
  }

  const fill = getRandomGradientFill(parent)

  const childrenCount: Vector2 = sortByDimensions(
    [1, 1], // random(3), random(3)],
    dimensions
  )
  // if (dimensions[0] === dimensions[1]) {
    // console.log('squircle')
  squircle(parent, position, dimensions, fill)
  // } else {
    // roundedRect(parent, position, dimensions, fill)
  // }
  const nested = parent.nested()
  const g = nested.group()
  
  const animateTransform = document.createElementNS(xmlns, 'animateTransform')
  const center = [
    dimensions[0] / 2,
    dimensions[1] / 2,
  ].join(' ')
  animateTransform.setAttribute('attributeName', 'transform')
  animateTransform.setAttribute('type', 'rotate')
  animateTransform.setAttribute('from', `0 ${center}`)
  animateTransform.setAttribute('to', `${Math.random() > 0.5 ? '-' : ''}360 ${center}`)
  animateTransform.setAttribute('dur', `${random(60) + 20}s`)
  animateTransform.setAttribute('repeatCount', 'indefinite')

  g.node.appendChild(animateTransform)
  nested.attr({ x: round(position[0], 2), y: round(position[1], 2) })

  const [cols, rows] = childrenCount
  if (cols === 0 || rows === 0) return

  const parentPaddedDimensions = sub2(
    dimensions,
    0
  )
  const childDimensions: Vector2 = div(parentPaddedDimensions, childrenCount)

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const coord: Vector2 = [x, y]

      const translate: Vector2 = div2(
        sub(dimensions, parentPaddedDimensions),
        2
      )
      const childPosition: Vector2 = add(mul(coord, childDimensions), translate)
      const paddedChildDimensions = sub2(
        childDimensions,
        38
      )

      const paddedChildTranslate: Vector2 = div2(
        sub(childDimensions, paddedChildDimensions),
        2
      )
      const paddedChildPosition = add(childPosition, paddedChildTranslate)

      drawGroup(
        g,
        paddedChildPosition,
        paddedChildDimensions,
        depth + 1
      )
    }
  }
}

function sortByDimensions(
  childrenCount: Vector2,
  parentDimensions: Vector2
): Vector2 {
  if (parentDimensions[0] === parentDimensions[1] || Math.random() > 0.5) {
    return childrenCount
  }
  if (parentDimensions[0] > parentDimensions[1]) {
    return childrenCount.sort((a, b) => (a > b ? 1 : -1))
  }
  const result = childrenCount.sort((a, b) => (a > b ? -1 : 1))
  return result
}
