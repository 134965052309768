import { Container, Gradient } from '@svgdotjs/svg.js'
import { random256, round } from '../utils'

export type RGB = [number, number, number]
export type RGBA = [number, number, number, number]
export type ColorCorrection = [number, number, number]

export function bias(color: RGBA, bias: ColorCorrection): RGBA {
  return [
    (color[0] * bias[0]) << 0,
    (color[1] * bias[1]) << 0,
    (color[2] * bias[2]) << 0,
    color[3]
  ]
}

export function RGBA2RGB(c: RGBA): RGB {
  return [c[0], c[1], c[2]]
}


export function getGradientFill(
  parent: Container,
  fromColor: RGBA,
  toColor: RGBA
): Gradient {
  const from = RGBString(RGBA2RGB(fromColor))
  const to = RGBString(RGBA2RGB(toColor))

  const fromOpacityString = round(fromColor[3], 2)
  const toOpacityString = round(toColor[3], 2)

  const fill = parent
    .gradient('linear', (add) => {
      add
        .stop(0, from)
        .attr({ style: `stop-color:${from};stop-opacity:${fromOpacityString}` })
      add
        .stop(1, to)
        .attr({ style: `stop-color:${to};stop-opacity:${toOpacityString}` })
    })
    .from(0, 0)
    .to(0, 1)
  return fill
}

export function getRandomGradientFill(parent: Container): Gradient {
  const fromColor = bias(randomRGBA(), [1, 0.5, 0.8])
  const toColor = bias(randomRGBA(), [1, 0.5, 0.8])
  return getGradientFill(parent, fromColor, toColor)
}

export function randomRGB(): RGB {
  return [random256(), random256(), random256()]
}

export function randomRGBA(): RGBA {
  return [random256(), random256(), random256(), Math.random()]
}

export function randomRGBACSS(): string {
  const color = randomRGBA()
  return RGBAString(color)
}

export function randomRGBCSS(): string {
  const color = randomRGB()
  return RGBString(color)
}

export function RGBAString(color: RGBA): string {
  return `rgba(${color.join(',')})`
}

export function RGBString(color: RGB): string {
  return `rgb(${color.join(',')})`
}
