export type Vector2 = [number, number]

export const mul2 = (matrix: Vector2, multiplier: number): Vector2 => [
  matrix[0] * multiplier,
  matrix[1] * multiplier
]

export function mul(a: Vector2, b: Vector2): Vector2 {
  return [a[0] * b[0], a[1] * b[1]]
}

export function div(a: Vector2, b: Vector2): Vector2 {
  return [a[0] / b[0], a[1] / b[1]]
}

export function div2(a: Vector2, div: number): Vector2 {
  return [a[0] / div, a[1] / div]
}

export function add(a: Vector2, b: Vector2): Vector2 {
  return [a[0] + b[0], a[1] + b[1]]
}

export function add2(a: Vector2, n: number): Vector2 {
  return [a[0] + n, a[1] + n]
}

export function sub(a: Vector2, b: Vector2): Vector2 {
  return [a[0] - b[0], a[1] - b[1]]
}

export function min(a: Vector2): Vector2 {
  const value = Math.min(a[0], a[1])
  return [value, value]
}

export function min1(a: Vector2): number {
  const value = Math.min(a[0], a[1])
  return value
}

export const random256 = (): number => (Math.random() * 255) << 0
export const sort = (numbers: number[]): number[] => {
  return numbers.sort((a: number, b: number) => (a > b ? 1 : -1))
}
// export const min = (numbers: number[]): number => sort(numbers)[0]

function last<T>(elements: T[]): T {
  return elements[elements.length - 1]
}

export const max = (numbers: number[]): number => last(sort(numbers))

export function max2(v: Vector2): Vector2 {
  return [max(v), max(v)]
}

export function center(
  parentPosition: Vector2,
  parentSize: Vector2,
  size: Vector2
): Vector2 {
  return [
    (parentSize[0] - size[0]) / 2 + parentPosition[0],
    (parentSize[1] - size[1]) / 2 + parentPosition[1]
  ]
}

export function sub2(a: Vector2, n: number): Vector2 {
  return [a[0] - n, a[1] - n]
}

export function random(max: number): number {
  return (Math.random() * (max + 1)) << 0
}

export function sample<T>(array: T[]): T | undefined {
  if (array.length === 0) return undefined
  return array[random(array.length - 1)]
}

export function compress(v: Vector2, limit: number): Vector2 {
  return [compress1(v[0], limit), compress1(v[1], limit)]
}

export function compress1(n: number, limit: number): number {
  return n > 0 ? Math.min(n, limit) : Math.max(n, -limit)
}

export const sleep = async (delay: number): Promise<void> =>
  await new Promise((resolve) => setTimeout(resolve, delay))

export const between = (from: number, to: number): number =>
  Math.random() * (to - from) + from

export function round(number: number, decimals: number = 1): number {
  const divider = Math.pow(10, decimals)
  return Math.round(number * divider) / divider
}
